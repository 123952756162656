<template>
    <el-dialog
        ref="auditDialog"
        :title="title"
        :visible.sync="visible"
        width="40%"
    >
        <el-form ref="auditForm" :model="form" :rules="handleHasMainParts() ? extAttrRule : rules" label-position="top">
            <el-form-item :label="remarkLabel" prop="remark">
                <el-input v-model="form.remark" type="textarea" placeholder="请输入"></el-input>
            </el-form-item>
            <template v-if="handleHasMainParts()">
                <el-form-item label="Advance客户编码" prop="extAttr" >
                    <el-input v-model="form.extAttr" placeholder="请输入" @input="getAdvanceInfo"></el-input>
                </el-form-item>
                <el-form-item label="收货地址">
                    <el-input :value="area" placeholder="请输入" disabled></el-input>
                </el-form-item>
                <el-form-item label="详细地址">
                    <el-input :value="address" placeholder="请输入" disabled></el-input>
                </el-form-item>
            </template>
            <el-form-item label="附件" prop="fileIdList">
                <el-upload
                    :action="uploadAction"
                    :headers="uploadHeaders"
                    :multiple="true"
                    :show-file-list="true"
                    :file-list="attachFileList"
                    :limit="fileLimit"
                    :before-upload="handleBeforeUpload"
                    :on-success="handleFileUploaded"
                    :on-remove="handleFileRemove"
                >
                    <el-button icon="el-icon-paperclip" :disabled="attachFileList.length >= fileLimit">上传文件</el-button>
                    <div slot="tip" class="el-upload__tip">(最多可上传{{ fileLimit }}个, 每个不超过20M)</div>
                </el-upload>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel"> 取消 </el-button>
            <el-button type="primary" :loading="loading" @click="handleApproval"> 确认 </el-button>
        </span>
    </el-dialog>
</template>

<script>
import defaultSettings from "@/settings"
import { debounce } from '@/utils/debounce'
import { repairActionEnum, repairAuditStatusEnum } from "@/constant/enum"
import fa from "element-ui/src/locale/lang/fa";

const url = defaultSettings.hostName

export default {
    name: 'ApprovalConfirm',
    props: { },
    data() {
        return {
            visible: false,
            loading: false,
            action: "",
            form: {
                relatedId: '',      // 维修申请数据id
                auditStatus: '',    // 审核状态
                fileIdList: [],     // 附件
                remark: '',         // 审批意见
                extAttr: '',        // 扩展字段
            },
            attachFileList: [],
            advanceData: null,
			hasMainParts: "",               // 是否有主机/零件/选购件或耗材   1、有主机  2、有零件 3、没有
			rules: {
                remark: [
                    { required: true, message: "请输入意见", trigger: "blur" },
                ]
            },
			extAttrRule: {
				extAttr: [
					{ required: true, message: "请输入Advance客户编码", trigger: "blur" },
				],
				remark: [
					{ required: true, message: "请输入意见", trigger: "blur" },
				]
            },
            repairActionEnum,
            repairAuditStatusEnum,
        }
    },
    computed: {
        title() {
            if (this.action === repairActionEnum.REPAIR_APPROVAL_GRANT) return "同意"
            if (this.action === repairActionEnum.REPAIR_APPROVAL_RETURN) return "退回"
            if (this.action === repairActionEnum.REPAIR_APPROVAL_TERMINATE) return "终止"
            if (this.action === repairActionEnum.LOGISTICS_APPROVAL_GRANT) return "同意"
            if (this.action === repairActionEnum.LOGISTICS_APPROVAL_RETURN) return "退回"
            if (this.action === repairActionEnum.EXPENSE_APPROVAL_GRANT) return "同意"
            if (this.action === repairActionEnum.EXPENSE_APPROVAL_RETURN) return "退回"
            if (this.action === repairActionEnum.EXPENSE_APPROVAL_REJECT) return "拒绝"
            if (this.action === repairActionEnum.OUT_OF_STOCK) return "缺货等待"
            return "审批"
        },
        remarkLabel() {
            if (this.action === repairActionEnum.REPAIR_APPROVAL_RETURN) return "退回意见"
            if (this.action === repairActionEnum.REPAIR_APPROVAL_TERMINATE) return "终止意见"
            if (this.action === repairActionEnum.LOGISTICS_APPROVAL_RETURN) return "退回意见"
            if (this.action === repairActionEnum.EXPENSE_APPROVAL_RETURN) return "退回意见"
            if (this.action === repairActionEnum.EXPENSE_APPROVAL_REJECT) return "拒绝意见"
            return "审批意见"
        },
        fileLimit() {
            // 最大上传文件数
            return 5
        },
        uploadAction() {
            return url + "bsi/sysAttachFile/addRepairFileOss"
        },
        uploadHeaders() {
            const token = sessionStorage.getItem('token') || ''
            return {
                token: token
            }
        },
        area() {
            if (this.advanceData) {
                const province = this.advanceData.province || ''
                const city = this.advanceData.city || ''
                return `${province}${city}`
            }
            return ''
        },
        address() {
            return this.advanceData?.address || ''
        },
    },
    methods: {
        show(action, data) {
            this.action = action
			this.loading = false
            this.$refs.auditForm && this.$refs.auditForm.resetFields()
            this.$set(this, "attachFileList", [])
            this.form.relatedId = data.relatedId
			this.hasMainParts = data.hasMainParts
            this.$nextTick(() => {
				this.visible = true
				this.setFormStatus()
			})
        },
        handleCancel() {
            this.visible = false
        },
        setFormStatus() {
            // 维修审批同意
            if (this.action === repairActionEnum.REPAIR_APPROVAL_GRANT) {
                this.form.auditStatus = repairAuditStatusEnum.REPAIR_APPROVAL_GRANTED
				this.form.remark = '同意'
            }
            // 维修审批退回
            if (this.action === repairActionEnum.REPAIR_APPROVAL_RETURN) {
                this.form.auditStatus = repairAuditStatusEnum.REPAIR_APPROVAL_RETURNED
				this.form.remark = '退回'
			}
            // 维修审批终止
            if (this.action === repairActionEnum.REPAIR_APPROVAL_TERMINATE) {
                this.form.auditStatus = repairAuditStatusEnum.REPAIR_APPROVAL_TERMINATED
				this.form.remark = '终止'
            }

            // 物流审批同意
            if (this.action === repairActionEnum.LOGISTICS_APPROVAL_GRANT) {
                this.form.auditStatus = repairAuditStatusEnum.LOGISTICS_APPROVAL_GRANTED
				this.form.remark = '同意'
			}
            // 物流审批退回
            if (this.action === repairActionEnum.LOGISTICS_APPROVAL_RETURN) {
                this.form.auditStatus = repairAuditStatusEnum.LOGISTICS_APPROVAL_RETURNED
				this.form.remark = '退回'
            }

            // 费用审批通过
            if (this.action === repairActionEnum.EXPENSE_APPROVAL_GRANT) {
                this.form.auditStatus = repairAuditStatusEnum.EXPENSE_APPROVAL_GRANTED
				this.form.remark = '同意'
            }
            // 费用审批退回
            if (this.action === repairActionEnum.EXPENSE_APPROVAL_RETURN) {
                this.form.auditStatus = repairAuditStatusEnum.EXPENSE_APPROVAL_RETURNED
				this.form.remark = '退回'
			}
            // 费用审批拒绝
            if (this.action === repairActionEnum.EXPENSE_APPROVAL_REJECT) {
                this.form.auditStatus = repairAuditStatusEnum.EXPENSE_APPROVAL_REJECTED
				this.form.remark = '拒绝'
			}

            // 缺货等待
            if (this.action === repairActionEnum.OUT_OF_STOCK) {
                this.form.auditStatus = repairAuditStatusEnum.OUT_OF_STOCK
				this.form.remark = '缺货等待'
			}
        },
        handleApproval() {

            // if (!this.advanceData) {
            //     this.$message.error('请输入合法的Advance客户编码')
            //     return
            // }

            let that = this
            that.$refs['auditForm'].validate((valid, fields) => {
                if (!valid) {
                    return
                }

                that.loading = true
                that.$request.post(
                    "claimAuditAdd",
                    false,
                    that.form,
                    function (r) {
                        if (r.code == "0") {
                            that.visible = false
                            that.$emit("refresh")
                        } else {
							that.loading = false
                            that.$message.error(r.msg)
                        }
                    },
                    function () {
						that.loading = false
                    }
                )
            })
        },
        handleBeforeUpload(file) {
            // 上传前检查
            const limitSize = file.size / 1024 / 1024 <= 20
            if (!limitSize) {
                this.$message.warning('附件大小不能超过20M')
                return false
            }
        },
        // 上传附件成功
        handleFileUploaded(response, file, fileList) {
            this.attachFileList = fileList
            this.form.fileIdList = fileList.map(item => item.response.data.id)
        },
        handleFileRemove(file, fileList) {
            this.attachFileList = fileList
            this.form.fileIdList = fileList.map(item => item.response.data.id)
        },
        getAdvanceInfo: debounce(function () {
            if (!this.form.extAttr.trim()) {
                this.advanceData = null
                return
            }

            let that = this
            that.$request.get(
                "getAdvanceData",
                false,
                { advanceCode: this.form.extAttr },
                function (r) {
                    if (r.code == "0") {
                        that.advanceData = r.data
                    } else {
                        that.advanceData = null
                        that.$message.error(r.msg)
                    }
                },
                function () {
                }
            )
        }, 500),
		// 存在主要配件
		handleHasMainParts() {
			if (this.action === repairActionEnum.REPAIR_APPROVAL_GRANT) {
				return !(this.action === repairActionEnum.REPAIR_APPROVAL_GRANT && (this.hasMainParts === 3 || this.hasMainParts === '3'))
			}
			return false
		}
    }
}
</script>

<style scoped lang="scss">

</style>
