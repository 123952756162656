<template>
    <el-dialog
        title="快递单号"
        :visible.sync="visible"
        width="40%"
    >
        <el-form ref="form" :model="form">
            <table class="form-table fixed-table">
                <thead>
                <tr>
                    <th> 序号 </th>
                    <th class="col-required"> 快递单号 </th>
                    <th class="col-required"> 快递公司 </th>
                    <th> 操作 </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in form.trackList">
                    <td class="text-center"> {{ i+1 }} </td>
                    <td>
                        <el-form-item class="mb0" :prop="'trackList.'+i+'.trackingNumber'" :rules="trackingNumberRule">
                            <el-input v-model="form.trackList[i].trackingNumber" placeholder="请输入"></el-input>
                        </el-form-item>
                    </td>
                    <td>
                        <el-form-item class="mb0" :prop="'trackList.'+i+'.carrier'" :rules="carrierRule">
                            <el-input v-model="form.trackList[i].carrier" placeholder="请输入"></el-input>
                        </el-form-item>
                    </td>
                    <td>
                        <el-button type="text" class="btn-red" @click="handleRemoveTrack(i)">删除</el-button>
                    </td>
                </tr>
                </tbody>
            </table>
            <div>
                <el-button icon="el-icon-circle-plus-outline" @click="handleAddTrack">新增一项</el-button>
            </div>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel"> 取消 </el-button>
            <el-button type="primary" :loading="loading" @click="handleApproval"> 确认 </el-button>
        </span>
    </el-dialog>
</template>

<script>
import { repairActionEnum, repairAuditStatusEnum } from "@/constant/enum"

export default {
    name: 'LogisticsShipment',
    props: { },
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                id: "",
                trackList: [],      // 物流信息
            },
            trackStructure: {
                trackingNumber: "", // 快递单号
                carrier: "",        // 快递公司
            },
            trackingNumberRule: { required: true, message: "请输入快递单号", trigger: "blur" },
            carrierRule: { required: true, message: "请输入快递公司", trigger: "blur" },
            repairActionEnum,
            repairAuditStatusEnum,
        }
    },
    methods: {
        show(data) {
            this.loading = false
            this.$refs.form && this.$refs.form.resetFields()
            this.form.trackList = []
            this.form.id = data.relatedId
            this.$nextTick(() => { this.visible = true })
        },
        handleCancel() {
            this.visible = false
        },
        handleAddTrack() {
            this.form.trackList.push(JSON.parse(JSON.stringify(this.trackStructure)))
        },
        handleRemoveTrack(index) {
            this.form.trackList.splice(index, 1)
        },
        handleApproval() {
            if (this.form.trackList.length === 0) {
                this.$message.error("请添加快递信息")
                return
            }

            let that = this
            that.$refs['form'].validate((valid, fields) => {
                if (!valid) {
                    return
                }

                that.loading = true

                that.$request.post(
                    "claimAuthRepairOrderTrack",
                    false,
                    that.form,
                    function (r) {
                        if (r.code == "0") {
                            that.visible = false
                            that.$emit("refresh")
                        } else {
                            that.$message.error(r.msg)
                        }
                    },
                    function () {
                    }
                )
            })
        },
    }
}
</script>

<style scoped lang="scss">
.form-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    td {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
    thead {
        background-color: rgb(236, 242, 240);

        tr {
            text-align: left;

            th {
                padding: 18px 10px;

                &.col-required {
                    &:before {
                        content: '*';
                        color: #F56C6C;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 20px 10px;
                vertical-align: top;
            }
        }
    }
}
.fixed-table {
    th:first-child, td:first-child {
        position: sticky;
        right: 0;
        z-index: 1;
    }
    th {
        &:nth-child(1) {
            width: 30px;
        }
        &:nth-child(4) {
            width: 30px;
        }
    }
    td {
        vertical-align: middle !important;
    }
}
.text-center {
    text-align: center;
}
.btn-red {
    color: #E9515E;
    text-decoration: underline;
    cursor: pointer;
}
</style>
